import { AppError } from "/client/common/AppError.mjs";

var uniqId = 0;

export default {
	name: "Wysiwyg",
	template: `
		<div
			class="form-control"
			:id="uniqId"
			v-html="startValue" 
		>
		</div>
		<input :name="name" type="hidden" :value="modelValue" />`,

	props: {
		modelValue: String,
		name: String
	},
	data() {
		return {
			uniqId: `editor_${uniqId++}`,
			startValue: this.modelValue
		}
	},
	methods: {
		async updateModelValue() {
			const outputData = this.editor.root.innerHTML;
			this.$emit('update:modelValue', outputData);
		},

		async uploadFile(file) {
			const formData = new FormData();
			formData.append('image', file);

			const response = await fetch('/xhr/uploadImage', {
				method: 'POST',
				body: formData
			});

			if (response.status !== 200) {
				const errorOptions = { status: response.status };
				if (response.status === 400) {
					await response.json().then((json) => {
						errorOptions.errorJSON = json;
					}, () => {
						//nothing
					});
				}
				throw new AppError('Wysiwyg:UploadError', errorOptions);
			}

			return (await response.json())?.url;
		}
	},
	mounted() {
		this.editor = new Quill(`#${this.uniqId}`, {
			theme: "snow",
			modules: {
				toolbar: {
					container:[
						[{ header: ['2', '3', '4', false] }], 
						['bold', 'italic', 'underline', 'link'],
						[{ 'color': [] }],
						[{ list: 'ordered' }, { list: 'bullet' }], 
						['image'], 
						['clean']
					],
					handlers: {
						image: () => {
							const input = document.createElement('input');
							input.setAttribute('type', 'file');
							input.setAttribute('accept', 'image/*');
							input.click();
							input.onchange = async () => {
								const file = input.files[0];

								const filename = await this.uploadFile(file); // I'm using react, so whatever upload function
								const range = this.editor.getSelection();

								// this part the image is inserted
								// by 'image' option below, you just have to put src(link) of img here. 
								this.editor.insertEmbed(range.index, 'image', '/media/' + filename); 
							};
						}
					}
				},
				imageResize: {},
			}
		});

		this.editor.on('text-change', () => {
			this.updateModelValue();
		});
	}
}
