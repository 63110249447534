import { escape } from "/client/common/smallFunctions.mjs";
import DropdownMenu from "/client/views/components/DropdownMenu.js";

export default {
	name: "SelectDropdown",
	
	template: `
		<div :class="['dropdown', 'select-dropdown', 'btn-group', { 'select-dropdown_inline': inline }]">
			<input
				v-if="inputName"
				type="hidden"
				:name="inputName"
				:value="modelValue"
			/>
			<button
				type="button"
				data-bs-toggle="dropdown"
				:disabled="options.length === 0"
				:class="[
					'btn', 
					'select-dropdown__toggle', 
					{
						'dropdown-toggle': toggle,
						'btn-primary': primary && !outline,
						'btn-secondary': !primary && !outline,
						'btn-outline-primary': primary && outline,
						'btn-outline-secondary': !primary && outline,
						'btn-sm': small
					}
				]"
				v-html="buttonHTML"
				v-if="type === 'button'"
			/>
			<a
				class="select-dropdown__toggle"
				data-bs-toggle="dropdown"
				v-html="buttonHTML"
				v-if="type === 'link'"
			/>
			<dropdown-menu
				:options="options"
				@select="setOption"
			/>
		</div>`,

	components: {
		DropdownMenu
	},

	props: {
		options: Array,
		defaultTitle: String,
		defaultHtml: String,
		primary: Boolean,
		inline: Boolean,
		outline: Boolean,
		small: Boolean,
		toggle: {
			type: Boolean,
			default: true
		},
		type: {
			type: String,
			default: 'button'
		},
		modelValue: {},
		inputName: String,
		preIcon: String
	},

	methods: {
		getOptionTitle(cmpValue) {
			const option = this.getOption(cmpValue);
			return option && option.title;
		},

		getOption(cmpValue, fromOptions = this.options) {
			if (cmpValue?.value) cmpValue = cmpValue.value;
			return fromOptions.find(({ value }) => value === cmpValue)
		},

		setOption(option = {}) {
			this.$emit('update:modelValue', option.value);
			this.title = option.title;
		},

		checkDefaultSelected() {
			// Если есть опции и есть стандартное значение
			if (!this.options.length) return;
			const defaultOption = this.options.find(({ defaultSelected }) => defaultSelected);
			if (!defaultOption) return;
			// Проверяем что если ничего не выбрано или выбрано то чего нет в списке - меняем значение на стандартное
			if (this.modelValue === undefined || !this.getOption(this.modelValue)) {
				this.$nextTick(() => {
					this.setOption(defaultOption);
				});
			}
		}
	},

	mounted() {
		this.checkDefaultSelected();
	},

	watch: {
		options() {
			this.checkDefaultSelected()
		}
	},

	computed: {
		buttonHTML() {
			return (this.modelValue !== undefined
				? escape(this.getOptionTitle(this.modelValue))
				: this.defaultTitle
					? (this.preIcon ? feather.icons[this.preIcon].toSvg() : '') + escape(this.defaultTitle)
					: this.defaultHtml) + ' ';
		}
	},

	emits: ['update:modelValue']
}