import SelectDropdown from "/client/views/components/SelectDropdown.js";
import DropdownMenu from "/client/views/components/DropdownMenu.js";

export default {
	name: "QuerySortEditor",
	
	template: `
		<div class="query-sort-editor list-group">
			<div class="list-group-item query-sort-editor__row" v-for="field in modelValue">
				<button class="btn-close query-sort-editor__close" @click="removeField($event, field)" />
				<div class="row align-items-baseline">
					<div class="col">{{ field.title }}</div>
					<div class="col query-sort-editor__select-dd-row">
						<select-dropdown
							:small="true"
							:options="sortTypes"
							v-model="field.sortType"
							@update:model-value="updateModelValue"
						/>
					</div>
				</div>
			</div>
			<div class="list-group-item disabled" v-if="modelValue.length === 0">
				Нет выбранных полей
			</div>
			<div class="list-group-item active link query-sort-editor__add" v-if="leftAllowedFields.length" data-bs-toggle="dropdown">
				Добавить поле ${feather.icons.plus.toSvg()}
			</div>
			<dropdown-menu
				v-if="leftAllowedFields.length"
				:options="leftAllowedFields"
				@select="addField"
			/>
		</div>`,

	components: {
		SelectDropdown,
		DropdownMenu
	},

	methods: {
		updateModelValue() {
			this.$emit('update:modelValue', this.modelValue.slice());
		},
		addField(option) {
			const newSelectedFields = this.modelValue.concat({
				title: option.title,
				fieldName: option.value,
				sortType: 'asc'
			});
			this.$emit('update:modelValue', newSelectedFields);
		},
		removeField(event, option) {
			if (event.defaultPrevented) return;

			const index = this.modelValue.indexOf(option);
			if (index !== -1) {
				event.preventDefault();
				const newSelectedFields = [...this.modelValue.slice(0, index),  ...this.modelValue.slice(index + 1)];
				this.$emit('update:modelValue', newSelectedFields);
			}
		}
	},

	props: {
		allowedFields: Array,
		modelValue: Array
	},

	data() {
		return {
			sortTypes: [
				{ value: "asc", title: "По возрастанию" },
				{ value: "desc", title: "По убыванию" },
			]
		}
	},

	computed: {
		// Возвращаем все не найденые поля
		leftAllowedFields() {
			return this.allowedFields.filter(allowedField => {
				const foundedInSelected = this.modelValue.find(({ fieldName }) => {
					return fieldName === allowedField.value
				});
				return !foundedInSelected;
			});
		}
	},
	emits: ["update:modelValue"]
}