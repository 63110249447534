export function initHelp(element) {
	for (const popoverTriggerEl of document.querySelectorAll('[data-bs-toggle="popover"][data-help]')) {
		const key = popoverTriggerEl.dataset.help;
		const title = popoverTriggerEl.getAttribute('title');
		popoverTriggerEl.removeAttribute('data-help');
		popoverTriggerEl.setAttribute('tabindex', 0);

		const content = HELP[key];
		if (!content) return console.error(`Текст подсказки "${key}" не найден`)

		new bootstrap.Popover(popoverTriggerEl, {
			trigger: "focus",
			sanitize: false,
			html: true,
			content: HELP[key],
			template: `<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-body"></div></div>`
		});

		popoverTriggerEl.setAttribute('title', title);
	};
}