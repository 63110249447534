import SelectDropdown from "/client/views/components/SelectDropdown.js";
import QueryFieldsEditor from "./QueryFieldsEditor.js";
import QueryConditionsEditor from "./QueryConditionsEditor.js";
import QueryGroupingEditor from "./QueryGroupingEditor.js";
import QuerySortEditor from "./QuerySortEditor.js";

export default {
	name: "QueryConstructor",
	template: `
	<div class="query-constructor">
		<div class="mt-3 mb-4">
			<label class="form-label d-block">
				Таблица для выборки <a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="selectTable">${feather.icons['help-circle'].toSvg()}</a>
			</label>
			<select-dropdown
				defaultTitle="Выберите..."
				:options="allowedTables"

				v-model="selectedTable"
				:small="true"
				@update:model-value="newConstructorSettings"
			/>
		</div>
		<div class="mb-4">
			<label class="form-label">
				Поля выборки&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="selectFields">${feather.icons['help-circle'].toSvg()}</a>
			</label>
			<query-fields-editor 
				:allowed-fields="allowedFields"
				v-model="selectedFields"
				@update:model-value="newConstructorSettings"
			/>
		</div>
		<div class="mb-4">
			<label class="form-label">
				Условия выборки&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="queryConditions">${feather.icons['help-circle'].toSvg()}</a>
			</label>
			<query-conditions-editor 
				:allowed-fields="allowedConditionFields"
				v-model="selectedConditions"
				@update:model-value="newConstructorSettings"
			/>
		</div>
		<div class="mb-4">
			<label class="form-label">
				Группировка&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="queryGroup">${feather.icons['help-circle'].toSvg()}</a>
			</label>
			<query-grouping-editor 
				:allowed-fields="allowedFieldsAndSynonyms"
				v-model="selectedGrouping"
				@update:model-value="newConstructorSettings"
			/>
		</div>
		<div class="mb-4">
			<label class="form-label">
				Сортировка&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="querySort">${feather.icons['help-circle'].toSvg()}</a>
			</label>
			<query-sort-editor 
				:allowed-fields="allowedFieldsAndSynonyms"
				v-model="selectedSort"
				@update:model-value="newConstructorSettings"
			/>
		</div>
	</div>
	`,


	props: {
		constructorSettings: {
			type: Object,
			default: () => {
				return {
					selectedTable: undefined,
					selectedFields: [],
					selectedConditions: [],
					selectedGrouping: [],
					selectedSort: []
				};
			}
		}
	},

	components: {
		SelectDropdown,
		QueryFieldsEditor,
		QueryConditionsEditor,
		QueryGroupingEditor,
		QuerySortEditor
	},

	methods: {
		newConstructorSettings() {
			const { selectedTable, selectedFields, selectedConditions, selectedGrouping, selectedSort } = this;
			const newConstructorSettings = { selectedTable, selectedFields, selectedConditions, selectedGrouping, selectedSort };
			this.$emit('update:constructor-settings', newConstructorSettings);
		},
	},

	computed: {
		allowedFieldsAndSynonyms() {
			return [
				...this.constructorSettings.selectedFields.map(({ synonym }) => {
					return { value: synonym, title: synonym }
				}),

				...this.allowedFields.map(({ value }) => {
					return { value, title: value }
				})
			];
		}
	},

	data() {

		const { selectedTable, selectedFields, selectedConditions, selectedGrouping, selectedSort } = this.constructorSettings;

		const allowedConditionFields = [
			{ value: "id", title: "ID записи (id)", synonym: "ID записи" },
			{ value: "cDate", title: "Дата (cDate)", synonym: "Дата" },
			{ value: "type_msg", title: "Тип сообщения (type_msg)", synonym: "Тип сообщения" },
			{ value: "adr", title: "Погрузчик (adr)", synonym: "Погрузчик" },
			{ value: "dsc_cust", title: "Клиент (dsc_cust)", synonym: "Клиент" },
			{ value: "dsc_comp", title: "Компонент (dsc_comp)", synonym: "Компонент" },
			{ value: "mode", title: "Режим (mode)", synonym: "Режим" },
			{ value: "partial", title: "Ковш", synonym: "PATRIAL" },
			{ value: "total", title: "Машина/Вагон", synonym: "TOTAL" },
			{ value: "count", title: "Счетчик (counter)", synonym: "Счетчик" }
		]

		return {
			allowedTables: [
				{ value: "rt", title: "Таблица с данными о весе" },
				{ value: "pt", title: "Таблица с данными о работе спецтехники" },
			],

			allowedFields: [...allowedConditionFields,
				{ value: "allcount", title: "Всего строк", synonym: "Всего" }
			],
			allowedConditionFields,

			selectedTable,
			selectedFields,
			selectedConditions,
			selectedGrouping,
			selectedSort
		}
	}
}