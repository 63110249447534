// Модалка ошибки
let uniq = 1;

export default {
	name: "ErrorModal",
	
	template: `
	<div class="modal fade" :id="elementId" @[bootstrapHiddenEvent]="$emit('close')">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">{{ title }}</h5>
				</div>
				<div class="modal-body">
					{{ text }}
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
				</div>
			</div>
		</div>
	</div>
	`,

	emits: ["close"],

	props: {
		title: {
			type: String,
			default: "Ошибка"
		},
		text: {
			type: String,
			default: "Произошла внутренняя ошибка. Попробуйте повторить действие позже."
		}
	},

	mounted() {
		const modalEl = document.getElementById(this.elementId);
		const instance = new bootstrap.Modal(modalEl, {
			backdrop: "static",
			keyboard: true,
			focus: true
		});
		instance.show();
	},

	data() {
		return {
			elementId: `error-modal-${uniq++}`,

			bootstrapHiddenEvent: "hidden.bs.modal"
		}
	}
}