import Editor, { beforeInit } from '/client/views/Editor.js'
import MultiSelectDropdown from '/client/views/components/MultiSelectDropdown.js'

import * as CONSTS from "/client/common/consts.mjs";
window.CONSTS = CONSTS;

import { makeChartData, getChartOptions } from "/client/js/helpers/makeChartData.js"
import { printInit } from "/client/js/printInit.js";
import { chartSaveInit } from "/client/js/chartSaveInit.js";

window.makeChartData = makeChartData;
window.getChartOptions = getChartOptions;

(async () => {
	Chart.plugins.register({
		beforeDraw: function({ chart: { ctx, width, height} }) {
			ctx.fillStyle = 'white';
			ctx.fillRect(0, 0, width, height);
		}
	});

	await new Promise(window._onLoad);

	await beforeInit();

	printInit();
	chartSaveInit();

	const app = document.querySelector('#app');
	if (!app || typeof Vue === "undefined") return;
	Vue.createApp({
		components: {
			Editor,
			MultiSelectDropdown
		}
	}).mount(app);
})();