var uniq = 1;

export default {
	name: "MultiSelectDropdown",

	template: `
		<div class="multiselect-dropdown dropdown">
			<input
				v-if="inputName"
				type="hidden"
				:name="inputName"
				:value="inputOutputValue"
			/>
			<button type="button" data-bs-toggle="dropdown" aria-expanded="false" @[hideEvent]="hideHandler" class="btn btn-secondary dropdown-toggle">
				{{ getTitle() }}
			</button>
			<ul aria-labelledby="dropdownQueryConstructor" class="dropdown-menu" :id="idPrefix + 'menu'">
				<li v-for="option in options" :title="option.title" @click="handleLiClick" class="dropdown-item">
					<div class="form-check form-switch">
						<input class="form-check-input" type="checkbox" :value="option.value" :id="idPrefix + option.value" :checked="isChecked(option.value)" @change="handleChange">
						<label class="form-check-label" :for="idPrefix + option.value">{{ option.title }}</label>
					</div>
				</li>
			</ul>
		</div>`,

	props: {
		options: Array,
		defaultTitle: String,
		titleHandler: Function,
		inputName: String,
		modelValue: {
			type: Array,
			default: () => []
		}
	},

	methods: {
		handleChange(event) {
			const checkboxes = Array.from(this.$el.querySelectorAll('input[type="checkbox"]'));
			const value = checkboxes.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
			this.internalValue = value;
			this.$emit('update:modelValue', value);
		},

		hideHandler(event) {
			// Если клик был в дропдауне то не закрываем
			if (event.clickEvent && event.clickEvent.target.closest(`#${this.idPrefix}menu`)) {
				event.preventDefault();
			}
		},

		handleLiClick(event) {
			if (event.target.classList.contains(`dropdown-item`)) {
				const checkbox = event.target.querySelector('input');
				checkbox.checked = !checkbox.checked;
				this.handleChange(event);
			}
		},

		getTitle() {
			if (this.internalValue.length) {
				if (this.titleHandler) return this.titleHandler(this.internalValue);
				return this.internalValue.reduce((acc, value) => {
					const founded = this.options.find(option => option.value == value);
					if (founded) acc = acc.concat(founded.title);
					return acc;
				}, []).join(', ');
			} else {
				return this.defaultTitle;
			}
		},
		isChecked(value) {
			return this.internalValue.some(cmp => cmp == value)
		}
	},

	computed: {
		inputOutputValue() {
			return this.internalValue.join(',');
		}
	},

	data() {
		return {
			title: null,
			idPrefix: `multiSelectDropdown${uniq++}_`,
			hideEvent: 'hide.bs.dropdown',
			internalValue: this.modelValue
		}
	},

	emits: ['update:modelValue']
}