export function escape(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

export function isFloat(input) { 
	return (parseFloat(input) + '') === input && parseFloat(input);
}

export function pick(input, ...keys) {
	const output = {};
	if (input) for (const key of keys) if (key in input) output[key] = input[key];
	return output;
}

export function escapeHTML(input) {
	return input
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
}
export function unescapeHTML(input) {
	return input
		.replace(/&amp;/g, '&')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
}
export function renderBlockData(json) {
	return json.blocks && json.blocks.map(function(block) {
		switch (block.type) {
		case 'header':
			return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
			break;
		case 'paragraph':
			return `<p>${block.data.text}</p>`;
			break;
		case 'delimiter':
			return '<hr />';
			break;
		case 'raw':
			return block.data.html;
			break;
		case 'list':
			return `<ol>${
				block.data.items.map(function(li) {
					return `<li>${li}</li>`;
				}).join('')
			}</ol>`;
			break;
		default:
			console.log('Unknown block type', block.type);
			console.log(block);
			break;
		}

	}).join('\n') || '';
}