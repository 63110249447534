export default {
	name: "DropdownMenu",
	
	template: `
		<ul class="dropdown-menu">
			<li v-for="option in options" :title="option.title" @click="setOption(option)">
				<a class="dropdown-item">{{ option.title }}</a>
			</li>
		</ul>`,

	props: {
		options: Array
	},

	methods: {

		getOption(cmpValue, fromOptions = this.options) {
			return fromOptions.find(({ value }) => value === cmpValue)
		},

		setOption(option = {}) {
			this.$emit('select', option);
		}
	},

	emits: ['select']
}