import SelectDropdown from "/client/views/components/SelectDropdown.js";
import DropdownMenu from "/client/views/components/DropdownMenu.js";

export default {
	name: "QueryFieldsEditor",
	
	template: `
		<div class="query-fields-editor list-group">
			<div class="list-group-item query-fields-editor__row" v-for="field in modelValue">
				<button class="btn-close query-fields-editor__close" @click="removeField($event, field)" />
				<div class="mb-1">
					{{ field.title }}
				</div>
				<div class="row align-items-end">
					<div class="mb-1 col">
						<small class="text-muted d-block">Функция обработки значения&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="fieldFunc">${feather.icons['help-circle'].toSvg()}</a></small>
						<select-dropdown
							:options="allowedFunctions"
							:small=true

							v-model="field.func"
							@update:model-value="updateModelValue"
						/>
					</div>
					<div class="mb-1 col">
						<small class="text-muted d-block">Вычитание&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="fieldSubtract">${feather.icons['help-circle'].toSvg()}</a></small>
						<select-dropdown
							:options="allowedSubtract"
							:small=true

							v-model="field.subtract"
							@update:model-value="updateModelValue"
						/>
					</div>
					<div class="mb-1 col">
						<small class="text-muted" v-if="field.subtract !== null">Константа&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="fieldConst">${feather.icons['help-circle'].toSvg()}</a></small>
						<input v-if="field.subtract !== null"
							type="number"
							class="form-control form-control-sm"

							v-model.number="field.const"
							@update:model-value="updateModelValue"
						/>
					</div>
					<div class="mb-1 col">
						<small class="text-muted d-block">Округление&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="fieldRound">${feather.icons['help-circle'].toSvg()}</a></small>
						<select-dropdown
							:options="allowedRounds"
							:small=true

							v-model="field.round"
							@update:model-value="updateModelValue"
						/>
					</div>
					<div class="mb-1 col">
						<small class="text-muted" v-if="field.round !== null">Делитель&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="fieldDivider">${feather.icons['help-circle'].toSvg()}</a></small>
						<input v-if="field.round !== null"
							type="number"
							class="form-control form-control-sm"

							v-model.number="field.divider"
							@update:model-value="updateModelValue"
						/>
					</div>
					<div class="mb-1 col">
						<small class="text-muted">Синоним&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="fieldSynonym">${feather.icons['help-circle'].toSvg()}</a></small>
						<input
							type="text"
							class="form-control form-control-sm"

							v-model="field.synonym"
							@update:model-value="updateModelValue"
						/>
					</div>
				</div>
			</div>
			<div class="list-group-item disabled" v-if="modelValue.length === 0">
				Нет выбранных полей
			</div>
			<div class="list-group-item active link query-fields-editor__add" v-if="leftAllowedFields.length" data-bs-toggle="dropdown">
				Добавить поле ${feather.icons.plus.toSvg()}
			</div>
			<dropdown-menu
				v-if="leftAllowedFields.length"
				:options="leftAllowedFields"
				@select="addField"
			/>
		</div>`,

	components: {
		SelectDropdown,
		DropdownMenu
	},

	methods: {
		updateModelValue() {
			this.$emit('update:modelValue', this.modelValue.slice());
		},
		addField(option) {
			const newSelectedFields = this.modelValue.concat({
				title: option.title,
				synonym: option.synonym,
				func: null,
				round: null,
				subtract: null,
				const: 1,
				divider: 1,
				fieldName: option.value
			});

			this.$emit('update:modelValue', newSelectedFields);
		},
		removeField(event, option) {
			if (event.defaultPrevented) return;

			const index = this.modelValue.indexOf(option);
			this.modelValue.splice(index, 1);
			this.$emit('update:modelValue', this.modelValue);
		}
	},

	props: {
		allowedFields: Array,
		modelValue: {
			type: Array,
			default: []
		}
	},

	data: () => {
		return {
			allowedFunctions: [
				{ value: "sum", title: "Сумма" },
				{ value: "avg", title: "Среднее значение" },
				{ value: "max", title: "Максимальное значение" },
				{ value: "min", title: "Минимальное значение" },
				{ value: "day", title: "День месяца" },
				{ value: "month", title: "Месяц" },
				{ value: "year", title: "Год" },
				{ value: null, title: "Без функции" }
			],

			allowedRounds: [
				{ value: "round-3", title: "до 1000" },
				{ value: "round-2", title: "до 100" },
				{ value: "round-1", title: "до 10" },
				{ value: "round0", title: "до целого" },
				{ value: "round1", title: "до 0.1" },
				{ value: "round2", title: "до 0.01" },
				{ value: "round3", title: "до 0.001" },
				{ value: null, title: "Без округления" }
			],

			allowedSubtract: [
				{ value: null, title: "Нет константы" },
				{ value: "subtractTo", title: "Вычесть число" },
				{ value: "subtractFrom", title: "Вычесть из числа" }
			]
		}
	},

	computed: {
		// Возвращаем все не найденые поля
		leftAllowedFields() {
			return this.allowedFields.filter(allowedField => {
				const foundedInSelected = this.modelValue.find(({ value }) => {
					return value === allowedField.value
				});
				return !foundedInSelected;
			});
		}
	},
	
	emits: ['update:modelValue']

}