function beforePrint() {
	for (const instance of Object.values(Chart.instances)) {
		instance.resize();
	}
}

function afterPrint() {
	beforePrint();
}

export function printInit() {
	if (window.matchMedia) {
		var mediaQueryList = window.matchMedia('print');
		mediaQueryList.addListener(function (mql) {
			if (mql.matches) {
				beforePrint();
			} else {
				afterPrint();
			}
		});
	}

	window.onbeforeprint = beforePrint;
	window.onafterprint = afterPrint;
}