import { ClientError } from '/client/common/AppError.mjs' ;

async function _fetch(url, data) {
	var body;
	var headers = {};
	if (data instanceof FormData) {
		body = data;
	} else {
		body = JSON.stringify(data);
		headers['Content-Type'] = 'application/json';
	}


	const response = await fetch(url, {
		method: 'POST',
	    // cache: 'no-cache',
	    // credentials: 'same-origin',
	    headers,
	    body
	});

	if (!response.ok || response.status !== 200) {
		throw new ClientError('Fetch:WrongStatus', { status: response.status });
	}
	
	return response;
}

async function fetchHTML() {
	const response = await _fetch(...arguments);
	return await response.text();
}

async function fetchJSON() {
	const response = await _fetch(...arguments);
	return await response.json();
}

export { fetchHTML, fetchJSON };