const COLOR_THEMES = {
	table: {
		violet: 	{ title: "Фиолетовая", 	data: { cls: 'danger' } },
		dark: 		{ title: "Темная", 		data: { cls: 'dark' } },
		lightGray: 	{ title: "Светлосерая", data: { cls: 'light' }, defaultSelected: true },
		gray: 		{ title: "Серая", 		data: { cls: 'secondary' } },
		blue: 		{ title: "Голубая", 	data: { cls: 'info' } },
		yellow: 	{ title: "Желтая", 		data: { cls: 'warning' } },
		lightGreen: { title: "Салатовая", 	data: { cls: 'success' } },
		bordered: 	{ title: "Бордюры", 	data: { cls: 'bordered' } }

	},
	chart: {
		colorful: {
			title: "Цветная",
			data: {
				colors: ["#68d4cd", "#cff67b", "#94dafb", "#fd8080", "#6d848e", "#26a0fc", "#26e7a6", "#febc3b", "#fab1b2", "#8b75d7", "#ffeb62", "#795548", "#4caf50"]
			},
			defaultSelected: true
		},
		violetYellow: {
			title: "Желто-фиолетовая",
			data: {
				colors: ["#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600"]
			}
		},

		greenRed: {
			title: "Зелено-Желто-Красная",
			data: {
				colors: ["#00876c", "#3c986d", "#60a86d", "#84b76e", "#a8c671", "#cdd476", "#f4e07f", "#f4c76a", "#f3ad5a", "#ef9250", "#e9774c", "#e05b4d", "#d43d51"]
			}
		},
		redYellowBlue: {
			title: "Красно-желто-синяя",
			data: {
				colors: ["#ed254e","#f05352","#f38155","#f9dc5c","#dee38d","#c2eabd","#62827a","#011936","#24364c","#465362"]
			}
		},
		blueGreen: {
			title: "Сине-зеленая",
			data: {
				colors: ["#66008f", "#9b0985", "#c0307c", "#d95677", "#eb7c7a", "#f6a186", "#ffc49d", "#fcc27f", "#efc260", "#d9c640", "#b8cb1f", "#8ad000", "#3cd400"]
			}
		},
		redBlue: {
			title: "Красно-синяя",
			data: {
				colors: ["#c73838", "#d94a5b", "#e85e7e", "#f275a0", "#f98cc1", "#fda4de", "#ffbdf9", "#eca7f2", "#d692ed", "#bc7fe8", "#9f6ee4", "#7b5ee1", "#4951de"]
			}
		},
		limeNavy: {
			title: "Лайм-синяя",
			data: {
				colors: ["#ceff1a","#c5ea2a","#bcd43a","#aaa95a","#969564","#82816d","#62616a","#414066","#2e3748","#1b2d2a"]
			}
		}
	}
}

export {
	COLOR_THEMES
}
