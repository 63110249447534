const distributeArray = (input, count) => {
	input = input.concat();
	const output = [];
	const countByChunk = Math.floor(input.length / count);
	let toAddInChunks = input.length % count;
	for (let i = 0; i < count; i++) {
		let cutLength = countByChunk;
		if (toAddInChunks) {
			cutLength++;
			toAddInChunks--;
		}
		output.push(input.splice(0, Math.min(cutLength, input.length)));
	}
	return output;
};
const contractArray = (input, outputLength) => {
	input = input.concat();
	const deltaLength = Math.max(input.length - outputLength, 0);
	if (deltaLength === 0) return input;
	// Делим массив на количество элементов которые надо вычесть
	input = distributeArray(input, deltaLength + 1);
	// и во всех элементах кроме последнего вычитаем по последнему элементу
	for (let i = 0; i < input.length - 1; i++) {
		input[i].pop();
	}
	// клеим обратно
	return input.reduce((acc, value) => { return acc.concat(value); }, [])
}

export function makeChartData({ fields, rows, colorTheme, chartType }) {
	// Определяем паттерн.
	var labels = [];
	var datasets = [];


	// Генерация цветов из представленной паллитры
	function getColorSettings(index, { count, makeArray } = {}) {
		// Сокращаем массив
		let colorArray = colorTheme.colors;
		if (count && count < colorArray.length) {
			colorArray = contractArray(colorArray, count);
		}


		const res = {
			borderColor: makeArray
				? Array.from(' '.repeat(count)).map((e, i) => {
					return colorArray[(i+index) % colorArray.length];
				})
				: colorArray[index % colorArray.length],
			borderWidth: 1,
			backgroundColor: makeArray
				? Array.from(' '.repeat(count)).map((e, i) => {
					return Chart.helpers.color(colorArray[(i+index) % colorArray.length]).alpha(.5).rgbString();
				})
				: Chart.helpers.color(colorArray[index % colorArray.length]).alpha(.5).rgbString()
		};
		if (chartType === "line") {
			res.fill = false;
			res.borderWidth = 2;
		}
		if (chartType === "stepped") {
			res.fill = false;
			res.borderWidth = 2;
			res.steppedLine = "middle";
		}
		if (chartType === "area") {
			res.fill = true;
			res.borderWidth = 1;
		}
		return res;
	}

	// Если третья колонка датасеты
	if (fields.length === 3 && rows[0] && isNaN(parseFloat(rows[0][fields[2].name]))) {
		// Колонки должны быть уникальными
		var xFieldName = fields[0].name;
		var valueFieldName = fields[1].name;
		var datasetFieldName = fields[2].name;
		labels = rows.reduce(function(acc, row) {
			if (!acc.includes(row[xFieldName])) {
				acc.push(row[xFieldName]);
			}
			return acc;
		}, []);

		// Теперь достаем все уникальные названия датасетов
		var datasetLabels = rows.reduce(function(acc, row) {
			if (!acc.includes(row[datasetFieldName])) {
				acc.push(row[datasetFieldName]);
			}
			return acc;
		}, []);

		// Теперь бежим по каждому лейблу в оси Х и наполняем датасет
		datasets = datasetLabels.map(function (datasetLabel, i) {
			return Object.assign({
				label: datasetLabel,
				data: labels.map(function (xFieldValue) {
					var foundedRow = rows.find(function(row) {
						return row[xFieldName] === xFieldValue 
							&& row[datasetFieldName] === datasetLabel;
					});
					return foundedRow ? foundedRow[valueFieldName] : 0
				})
			}, getColorSettings(i));
		});

	// первая колонка - ось Икс. Остальные это каждый датасет
	} else if (fields.length > 2){

		var xField = fields[0];
		var yFields = fields.slice(1);

		datasets = yFields.map(function(field, index) {
			return Object.assign({
				label: field.name,
				data: rows.map(row => row[field.name]),
			}, getColorSettings(index, { count: yFields.length }))
		});
		labels = rows.map(row => row[xField.name]);

	// Если только одно поле
	} else if (fields.length == 2){

		var xField = fields[0];
		var yFields = fields.slice(1);
		const colorOptions = {};
		if (chartType === "pie" || chartType === "doghnut") {
			colorOptions.count = rows.length;
			colorOptions.makeArray = true;
		}

		datasets = yFields.map(function(field, index) {
			return Object.assign({
				label: field.name,
				data: rows.map(row => row[field.name]),
			}, getColorSettings(index, colorOptions))
		});
		labels = rows.map(row => row[xField.name]);

	// Если только одно поле
	} else if (fields.length === 1) {
		var fieldName = fields[0].name;
		labels = [fieldName];
		datasets = [
			Object.assign({
				data: rows.map(row => row[fieldName]),
			}, getColorSettings(0, { count: rows.length, makeArray: true }))
		];
	}

	return { labels, datasets };
}

export function getChartOptions(chartType) {
	switch (chartType) {
	case "pie":
	case "doghnut":
		return {
			animation: { animateScale: true }
		}
	}
}