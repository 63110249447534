export class AppError extends Error {
	constructor(message, options = {}, params) {
		super(...arguments);
		for (const [optionKey, value] of Object.entries(options)) {
			if (this[`define_${optionKey}`]) this[`define_${optionKey}`](value);
			else this.define_info({ [optionKey]: value });
		} 
		if (params) {
			Object.assign(this, params);
		}
 	}
	
	get name() {
		return "AppError";
	}

 	define_info(value) {
 		if (!this.info) this.info = {};
 		Object.assign(this.info, value);
 	}

 	define_error(from) {
 		this.from = from;
 	}
 	define_message(message) {
 		this._message = message;
 	}

 	output() {
 		return this.constructor.output(this);
 	}

 	static output(error) {
 		let outputText = ""
 		while (error) {
            if (typeof error === "string") {
            	outputText += error;
            	break;
            }
	 		if (error.stack) outputText += error.stack;
	 		if (error.info) outputText += 'INFO\n' + JSON.stringify(error.info, true);
	 		error = error.from;
	 		if (error) outputText += '\nFROM:';
	 	}
	 	if (outputText) {
	 		console.error(outputText);
	 		if (console.telegram) console.telegram(outputText);
	 	}
 	}
}

export class ClientError extends AppError {
	get name() {
		return "ClientError";
	}

	define_status(value) {
		this.status = value;
	}
}