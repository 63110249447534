import QueryConstructor from "./QueryConstructor/QueryConstructor.js";
import SelectDropdown from "/client/views/components/SelectDropdown.js";
import ErrorModal from "/client/views/components/ErrorModal.js";
import Wysiwyg from "/client/views/components/Wysiwyg.js";

import { fetchHTML } from "/client/js/helpers/fetch.js";
import { isFloat, pick, unescapeHTML } from "/client/common/smallFunctions.mjs";
import { AppError } from "/client/common/AppError.mjs";
import { initHelp } from "/client/js/help.js";

function computeQuery(companyId) {
	const { selectedTable, selectedFields, selectedConditions, selectedGrouping, selectedSort } = this.constructorSettings;
	if (!selectedTable)
		return;

	if (selectedFields.length === 0) 
		return;

	let query = '';
	
	const selectValue = selectedFields.map((field) => {
		const { fieldName, func, round, synonym, divider, subtract, const: constant } = field;

		// Функция обработки
		let text = `\`${fieldName}\``;
		switch(fieldName) {
		case "allcount":
			text = `COUNT(*)`
		}


		switch(func) {
		case "sum":
			text = `SUM(${text})`;
			break;
		case "avg":
			text = `AVG(${text})`;
			break;
		case "max":
			text = `MAX(${text})`;
			break;
		case "min":
			text = `MIN(${text})`;
			break;
		case "day":
			text = `DATE_FORMAT(${text}, "%e %M")`;
			break;
		case "month":
			text = `DATE_FORMAT(${text}, "%M %Y")`;
			break;
		case "year":
			text = `DATE_FORMAT(${text}, "%Y")`;
			break;
		}

		switch (subtract) {
		case "subtractTo":
			text = `${text} - ${constant}`
			break;
		case "subtractFrom":
			text = `${constant} - ${text}`
			break;
		}

		// Функция округления и делителя
		if (round !== null) {
			const floatDivider = parseFloat(divider) || 0;
			const roundValue = parseInt(round.slice('round'.length));
			if (isNaN(roundValue)) throw new AppError('Round:IsNaN', { field });

			text = `ROUND(${text}/${floatDivider},${roundValue})`;
		}

		// Синоним
		if (synonym) {
			text = `${text} as '${synonym}'`
		}
		return text;
	}).join(', ');

	// TODO tableNAME + idAdmin
	query = `SELECT ${selectValue} FROM ${selectedTable}${companyId}`;

	if (selectedConditions.length) {
		const conditions = selectedConditions.map(({ type, comprasion, comb,  fieldName }, index) => {
			let conditionText = '';

			if (comb && index) {
				conditionText = `${comb.toUpperCase()} `;
			}

			const comprasionFloat = isFloat(comprasion);
			if (!comprasionFloat) {
				comprasion = `'${comprasion}'`;
			}


			switch (type) {
			case "gt":
				conditionText += `\`${fieldName}\`>${comprasion}`;
				break;
			case "lt":
				conditionText += `\`${fieldName}\`<${comprasion}`;
				break;
			case "eq":
				conditionText += `\`${fieldName}\`=${comprasion}`;
				break;
			case "gte":
				conditionText += `\`${fieldName}\`>=${comprasion}`;
				break;
			case "lte":
				conditionText += `\`${fieldName}\`<=${comprasion}`;
				break;
			case "neq":
				conditionText += `\`${fieldName}\`!=${comprasion}`;
				break;
			case "like":
				if (comprasionFloat) comprasion = `'${comprasion}'`;
				conditionText += `\`${fieldName}\` LIKE ${comprasion}`;
				break;
			}
			
			return conditionText;
		}).join(' ');

		query += ` WHERE ${conditions}`;
	}

	if (selectedGrouping.length > 0) {
		const groupingText = selectedGrouping.map(({ fieldName }) => {
			return `\`${fieldName}\``;
		}).join(', ');

		query += ` GROUP BY ${groupingText}`;
	}

	if (selectedSort.length > 0) {
		const sortText = selectedSort.map(({ fieldName, sortType }) => {
			return `'${fieldName}'${sortType === "desc" ? " DESC" : ''}`;
		}).join(', ');

		query += ` ORDER BY ${sortText}`;
	}

	query += ` LIMIT 500`;

	query += `\n\n#CONSTRUCTOR:${JSON.stringify({ selectedTable, selectedFields, selectedConditions, selectedGrouping, selectedSort })}`

	this.$emit('update:query', query);
	this.queryInputValue = query;
}

function computeTemplate() {
	let output = "";

	let templateType;
	switch(this.selectedVisual) {
	case undefined:
	case "code":
		return;
	case "table":
		templateType = "table";
		break;

	default:
		templateType = "chart"
	}

	if (templateType in TEMPLATES) {
		output += `- var chartType=${JSON.stringify(this.selectedVisual)};\n`;

		// 1. ColorTheme
		let theme;
		theme = CONSTS.COLOR_THEMES[templateType]?.[this.selectedColorTheme];
		if (theme) output += `- var colorTheme=${JSON.stringify(theme.data)};\n`;

		// 2. Сам шаблон
		output += TEMPLATES[templateType];

		// 3. Сохраняем настройку в сгенерированый код
		const saveVals = pick(this, 'selectedVisual', 'selectedColorTheme');
		output += `\n//- CONSTRUCTOR:${JSON.stringify(saveVals)}`;
	}
	if (output.length > 0) this.templateInputValue = output;
}
export default {
	name: "Editor",

	template: `

	<form id="editor" class="editor" action="." method="POST">
		<input type="hidden" name="order" :value="newOrder" v-if="newOrder !== undefined" />
		<div class="mb-5">
			<label class="form-label" for="visualNewName">Название визуализации
			</label>&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="visualName">${feather.icons['help-circle'].toSvg()}</a>
			<input id="visualNewName" class="form-control" type="text" name="title" v-model="titleInputValue" />
			<div class="form-text">Отображаемое имя в заголовке</div>
		</div>
		<div class="mb-5">
			<div class="mb-2 editor__main-select">
				<h3>
					Выборка из БД
					<select-dropdown
						defaultTitle="Как создать запрос"
						:options="menuModes"
						v-model="mode"
						:outline="true"
						:inline="true"
						:small="true"
					/>
				</h3>
			</div>
			<div class="editor__constructor" v-if="mode === 'constructor'">
				<query-constructor
					v-model:constructor-settings="constructorSettings"
				/>
			</div>
			<div v-show="mode === 'code'">
				<label class="form-label">Запрос:</label>
				<textarea class="form-control" name="query" v-model="queryInputValue" />
			</div>
		</div>

		<div class="mb-5">
			<h3>Отображение&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="queryVisualize">${feather.icons['help-circle'].toSvg()}</a></h3>
			<div class="mb-3 btn-group me-2">
				<select-dropdown
					defaultTitle="Как отображать"
					:options="allowedVisuals"
					v-model="selectedVisual"
					:primary="true"
				/>
				<select-dropdown
					v-if="allowedColorThemes.length > 0"
					defaultTitle="Выберите схему"
					:options="allowedColorThemes"
					v-model="selectedColorTheme"
				/>

				<button
					type="button"
					class="btn btn-success"
					v-if="allowRender"
					@click="fetchVisual"
				>
					Предпросмотр 
					${feather.icons.monitor.toSvg()}
				</button>
			</div>
			<div class="mb-3 btn-group" v-if="!textBefore || !textAfter">
				<button
					type="button"
					class="btn btn-outline-secondary"
					@click="showTextBefore = true"
					v-if="!showTextBefore"
				>
					${feather.icons.plus.toSvg()}
					Текст "до"
				</button>
				<button
					type="button"
					class="btn btn-outline-secondary"
					@click="showTextAfter = true"
					v-if="!showTextAfter"
				>
					${feather.icons.plus.toSvg()}
					Текст "после"
				</button>
				<div class="btn-group">
					<a class="btn btn-outline-secondary help-link" data-bs-toggle="popover" title="Кликните для подсказки" data-help="textBeforeAfter">${feather.icons['help-circle'].toSvg()}</a>
				</div>
			</div>
			<div class="mb-2" v-show="showTextBefore">
				<label class="form-label">Текст "до"</label>
				<wysiwyg name="textBefore" v-model="textBeforeInputValue" />
			</div>
			<div class="mb-2" v-show="selectedVisual && selectedVisual==='code'">
				<label class="form-label">Шаблон отображения</label>
				<textarea class="form-control" name="template" v-model="templateInputValue" />
			</div>
			<div class="mb-2" v-show="showTextAfter">
				<label class="form-label">Текст "после"</label>
				<wysiwyg name="textAfter" v-model="textAfterInputValue" />
			</div>
		</div>

		<div class="mb-5" v-if="!!fetchedVisualData">
			<h4>Предпросмотр</h4>
			<div class="mb-3 editor__render-visual" v-html="fetchedVisualData" />
		</div>

		<div class="mb-5">
			<h3>Настройки визуализации&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="visualizeSettings">${feather.icons['help-circle'].toSvg()}</a></h3>
			<input type="hidden" :value="JSON.stringify(computedSettings)" name="settings" />
			<div>
				<div class="small text-muted">Ширина отображения визуализации в отчете</div>
				<select-dropdown
					:options="allowedVisualWidth"
					v-model="selectedVisualWidth"
				/>
			</div>
		</div>

		<error-modal v-if="error" @close="this.error = null" />

		<div class="mb-5">
			<button class="btn btn-lg btn-success" type="submit">Сохранить</button>
		</div>
	</form>`,

	props: {
		query: {
			type: String,
			default: ""
		},
		template: {
			type: String,
			default: ""
		},
		companyId: {
			required: true,
			type: String
		},
		reportId: {
			required: true,
			type: Number
		},
		visualId: {
			default: "new"
		},
		title: {
			type: String,
			default: ""
		},
		settings: {
			type: Object,
			default: {}
		},
		newOrder: {
			type: Number
		},
		textBefore: {
			type: String,
			default: ""
		},
		textAfter: {
			type: String,
			default: ""
		}
	},

	components: {
		QueryConstructor,
		SelectDropdown,
		ErrorModal,
		Wysiwyg
	},

	methods: {
		computeQuery,
		computeTemplate,

		showError(error) {
			this.error = error;
		},

		async fetchVisual() {
			try {
				this.visualFetching = true;

				const { queryInputValue: query, templateInputValue: template, reportId, visualId } = this;
				this.fetchedVisualData = await fetchHTML('/xhr/renderVisual', { query, template, reportId, visualId });
			} catch (error) {
				this.showError(error)
			} finally {
				this.visualFetching = false;
			}
		}
	},
	data() {
		let constructorSettings = undefined;
		let mode = "constructor";
		let selectedVisual = undefined;
		let selectedColorTheme = undefined;


		// Последняя строка - настройка конструктора
		if (typeof this.query === "string" && this.query.trim().length > 0) {
			const PREFIX = "#CONSTRUCTOR:";
			const lastRow = this.query.split('\n').pop();

			if (lastRow.startsWith(PREFIX)) {
				const jsonString = lastRow.slice(PREFIX.length);
				try {
					const json = JSON.parse(jsonString);
					const { selectedTable, selectedFields, selectedConditions, selectedGrouping, selectedSort } = json;
					constructorSettings = { selectedTable, selectedFields, selectedConditions, selectedGrouping, selectedSort };			
				} catch(error) {
					error = new AppError('QueryConstructor:WrongJSON', { jsonString, query: this.query, error });
					error.output();
				}
			} else {
				mode = "code";
			}
		}

		// Последняя строка - настройка шаблона
		if (typeof this.template === "string" && this.template.trim().length > 0) {
			const PREFIX = "//- CONSTRUCTOR:";
			const lastRow = this.template.split('\n').pop();

			if (lastRow.startsWith(PREFIX)) {
				const jsonString = lastRow.slice(PREFIX.length);
				try {
					({ selectedColorTheme, selectedVisual } = JSON.parse(jsonString));
				} catch(error) {
					error = new AppError('QueryConstructor:WrongJSON', { jsonString, query: this.query, error });
					error.output();
				}
			} else {
				selectedVisual = "code";
			}
		}

		// Текст до и текст после
		const { textBefore, textAfter } = this;
		const showTextBefore = !!textBefore;
		const textBeforeInputValue = unescapeHTML(textBefore);
		const showTextAfter = !!textAfter;
		const textAfterInputValue = unescapeHTML(textAfter);

		// Разбираем settings которые приехали
		const { selectedVisualWidth = "one" } = this.settings;

		return {
			// Выбранный режим создания запроса
			mode,

			// Возможные режимы для создания запроса
			menuModes: [
				{ value: "constructor", title: "Конструктор" },
				{ value: "code", title: "SQL" }
			],

			// Произошедшая ошибка
			error: null,

			// Выбранный режим создания визуализации
			selectedVisual,
			allowedVisuals: [
				{ value: "code", 	title: "Код" },
				{ value: "table", 	title: "Таблица" },
				{ value: "bar", 	title: "Столбчатая диаграмма" },
				{ value: "pie", 	title: "Круговая диаграмма" },
				{ value: "doughnut",title: "Донат" },
				{ value: "line",	title: "Линейная диаграмма" },
				{ value: "stepped",	title: "Линейная с шагом" },
				{ value: "area",	title: "Диаграмма-область" },
			],

			// Фетч визуализации в процессе
			visualFetching: false,

			// Результат рендеринга запроса
			fetchedVisualData: null,


			constructorSettings,

			queryInputValue: this.query,
			titleInputValue: this.title,
			templateInputValue: unescapeHTML(this.template),

			// выбранная цветовая схема
			selectedColorTheme,

			// Настройки отчета

			// Ширина отчета
			allowedVisualWidth: [
				{ value: 'one', title: "Во всю ширину" },
				{ value: 'half', title: "1/2 ширины (если возможно)"}
			],
			selectedVisualWidth,


			showTextBefore,
			textBeforeInputValue,

			showTextAfter,
			textAfterInputValue
		}
	},

	computed: {
		allowedColorThemes() {
			switch (this.selectedVisual) {
			case "table":
				return Object.entries(CONSTS.COLOR_THEMES.table)
					.map(([value, { title, defaultSelected }]) => {
						return { title, value, defaultSelected };
					});

			case undefined:
			case "code":
				return [];
				
			default:
				return Object.entries(CONSTS.COLOR_THEMES.chart)
					.map(([value, { title, defaultSelected }]) => {
						return { title, value, defaultSelected };
					});

			}
		},

		allowRender() {
			return !!this.templateInputValue.trim();
		},

		computedSettings() {
			return pick(this, 'selectedVisualWidth');
		}
	},

	watch: {
		constructorSettings() {
			this.computeQuery(this.companyId);
		},

		selectedVisual() {
			this.computeTemplate();
		},

		selectedColorTheme() {
			this.computeTemplate();
		},

		// Запуск скриптов
		fetchedVisualData(newValue) {
			if (newValue) {
				this.$nextTick(() => {
					const scripts = this.$el.querySelectorAll('.editor__render-visual script');
					for (const script of scripts) {
						const scriptText = script.textContent;
						script.parentNode.removeChild(script);
						(0, eval)(scriptText); // Run it!
					}
				})
			}
		}
	},

	mounted() {
		initHelp(this.$el);
	},
	updated() {
		initHelp(this.$el);
	}
}

const templateNames = 'table chart'.split(' ');
const TEMPLATES = {};
// Подгрузка шаблонов
export function beforeInit() {
	return Promise.all(templateNames.map(async (templateName) => {
		const response = await fetch(`/client/templates/${templateName}.pug`);
		if (!response.ok) throw new AppError('Editor:BeforeInit:ResponseError', { response });
		TEMPLATES[templateName] = await response.text();
	}));
}