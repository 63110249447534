import SelectDropdown from "/client/views/components/SelectDropdown.js";
import DropdownMenu from "/client/views/components/DropdownMenu.js";

/*
	cond : {
		title,
		comb,
		type,
		comprasion
	}
*/
export default {
	name: "QueryConditionsEditor",
	
	template: `
		<div class="query-conditions-editor list-group">
			<div class="list-group-item query-conditions-editor__row" v-for="(cond, index) in modelValue">
				<button class="btn-close query-conditions-editor__close" @click="removeField($event, cond)" />
				<div class="mb-1">
					{{ cond.title }}
				</div>
				<div class="row row-cols-auto align-items-end">
					<div class="mb-1 col" v-if="index > 0">
						<select-dropdown
							:options="allowedTypes"
							:small=true
							:outline=true
							@update:model-value="updateModelValue"

							v-model="cond.comb"
						/>
					</div>
					<div class="mb-1 col">
						<div class="small text-muted">Условие&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="queryCondition">${feather.icons['help-circle'].toSvg()}</a></div>
						<select-dropdown
							:options="allowedConditions"
							:small=true
							@update:model-value="updateModelValue"

							v-model="cond.type"
						/>
					</div>
					<div class="mb-1 col">
						<div class="small text-muted">Значение&nbsp;<a class="help-link link-secondary" data-bs-toggle="popover" title="Кликните для подсказки" data-help="queryConditionValue">${feather.icons['help-circle'].toSvg()}</a></div>
						<input
							type="text"
							v-model="cond.comprasion"
							@update:model-value="updateModelValue"
							class="form-control form-control-sm"
						/>
					</div>
				</div>
			</div>
			<div class="list-group-item disabled" v-if="modelValue.length === 0">
				Нет выбранных полей
			</div>
			<div class="list-group-item active link query-conditions-editor__add" data-bs-toggle="dropdown">
				Добавить поле ${feather.icons.plus.toSvg()}
			</div>
			<dropdown-menu
				:options="allowedFields"
				@select="addField"
			/>
		</div>`,

	components: {
		SelectDropdown,
		DropdownMenu
	},

	methods: {
		updateModelValue() {
			this.$emit('update:modelValue', this.modelValue.slice());
		},
		addField(option) {
			const newSelectedFields = this.modelValue.concat({ 
				title: option.title,
				fieldName: option.value,
				comb: "and",
				type: "eq",
				comprasion: ""
			});
			this.$emit('update:modelValue', newSelectedFields);
		},
		removeField(event, option) {
			if (event.defaultPrevented) return;

			const index = this.modelValue.indexOf(option);
			if (index !== -1) {
				event.preventDefault();
				const newSelectedFields = [...this.modelValue.slice(0, index),  ...this.modelValue.slice(index + 1)];
				this.$emit('update:modelValue', newSelectedFields);
			}
		}
	},

	props: {
		allowedFields: Array,
		modelValue: Array
	},

	data: () => {
		return {
			allowedConditions: [
				{ value: "gt", title: "Больше" },
				{ value: "lt", title: "Меньше" },
				{ value: "eq", title: "Равно" },
				{ value: "gte", title: "Больше или равно" },
				{ value: "lte", title: "Меньше или равно" },
				{ value: "neq", title: "Не равно" },
				{ value: "like", title: "Есть (LIKE)" }
			],
			allowedTypes: [
				{ value: "or", title: "ИЛИ" },
				{ value: "and", title: "И" },
			]
		}
	},
	emits: ["update:modelValue"]
}