export function chartSaveInit() {
	for (const visual of document.querySelectorAll('.visual')) {
		const hasCanvas = visual.querySelector('canvas');
		for (const saveItem of visual.querySelectorAll('.visual__save__item_canvas')) {
			if (hasCanvas) {
				const { translit } = visual.dataset;
				saveItem.addEventListener('click', event => {
					if (event.defaultPrevented) return;
					event.preventDefault();
					const { type } = event.currentTarget.dataset;
					const link = document.createElement('a');
					link.download = translit + '.' + type;
					switch (type) {
					case "png":
						link.href = hasCanvas.toDataURL("image/png");
						break;
					case "jpg":
						link.href = hasCanvas.toDataURL('image/jpeg', 0.8);
					}
					link.click();
				});
			} else {
				saveItem.parentNode.removeChild(saveItem);
			} 
		}
	}
}